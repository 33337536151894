import { useCallback, useRef, useState } from 'react'
import { scroller } from 'react-scroll'
import {
  AboutMe,
  Contact,
  Footer,
  Header,
  Offer,
  Projects,
  References,
} from 'sections'
import { Layout } from 'shared/components'

const IndexPage = () => {
  const contactSectionRef = useRef<HTMLDivElement | null>(null)
  const [selectedVariant, setSelectedVariant] = useState('')

  const handleVariantChange = useCallback((variant: string) => {
    setSelectedVariant(variant)
    if (contactSectionRef.current) {
      scroller.scrollTo(contactSectionRef.current.id, {
        offset: -150,
        duration: 1000,
        smooth: 'easeInOutQuint',
      })
    }
  }, [])

  return (
    <Layout topBar={<Header />} footer={<Footer />}>
      <Projects />
      <AboutMe />
      <References />
      <Offer onVariantChange={handleVariantChange} />
      <Contact variant={selectedVariant} ref={contactSectionRef} />
    </Layout>
  )
}

export default IndexPage
